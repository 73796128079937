import { Web3Button } from "@thirdweb-dev/react";
import "./styles/Home.css";



export default function Home() {
  return (
    <div className="container">
      <main className="main">
	  <div className="grid">
        </div>
        <h1 className="title">
          BDKMV Token <a href="#">Claim Page</a>
        </h1>
        <br></br>
        <h3 className="Text" >
          BDKMV Tokens Cannot Be Claimed Before Presale End Date !!
        </h3>
        <br></br>
  <div className="connect">
    <Web3Button
      contractAddress="0x7e35C61f761B0e87AC790CA89ca546603F0F8372"
      action={(contract) => {
        contract.call("claim")
      }}
    >
      claim
    </Web3Button>
        </div>
	<h2 className="description">
          CopyRight BDKMV Token 2023
        </h2>
        </main> 
    </div>
  );
}
